<template>
    <div class="hc12Box">
        <div class="hbBox1" v-if="getAdvData(12).children">
            <a :href="getAdvData(12).children[0].url" target="_blank" rel="noopener noreferrer">
                <img :src="getAdvData(12).children[0].image" class="hb1Bg" />
                <div class="hb1p">
                    <div class="hb1P1">
                        <p class="h1p1">威有</p>
                        <p class="h1p2">品牌网站建设</p>
                    </div>
                    <div class="hb1Ps">
                        <p class="hp1">金牌设计团队 个性化定制设计 立体式品牌营销</p>
                        <p class="hp2">10000+</p>
                        <p class="hp1">品牌客户合作经验及真实案例展示</p>
                    </div>
                </div>
            </a>
        </div>
        <div class="hbBox2">
            <div class="hb2Top">我们的服务</div>
            <div class="dots">
                <div class="dot" v-for="el in 16" :key="el"></div>
            </div>
            <div class="dots" style="margin-top: 10px">
                <div class="dot" v-for="el in 16" :key="el"></div>
            </div>
            <div class="hb2List">
                <ul>
                    <li class="h2l wow animate__animated animate__bounceInUp" v-for="item in 6" :key="item">
                        <div class="h2Tb"></div>
                        <div class="h2lBox">
                            <div class="hb1">
                                <img src="http://iv.okvu.cn/vugw/img/hc1221.png" alt="" />
                            </div>
                            <p class="hb2">专属定制</p>
                            <p class="hb3">
                                通过对客户的公司，产品，文化以及<br />
                                针对的消费群体，市场做全面的需求调研，<br />
                                根据企业品牌的特性、市场定位，专属分析-<br />
                                策划-设计-开发符合企业品牌调性的 企业官网平台和方案。<br />
                            </p>
                        </div>
                        <div class="h2Bb"></div>
                    </li>
                </ul>
            </div>
            <div class="hb2More">
                详情咨询
                <div class="adbImg">
                    <img class="aiImg" :src="getWebData().public_phone" alt="" />
                </div>
            </div>
        </div>
        <div class="hbBox3">
            <div class="hb3Top wow animate__animated animate__bounceInUp">已有10000+品牌企业选择威有科技</div>
            <div class="hb3Box">
                <img src="http://iv.okvu.cn/vugw/img/hc1231.png" class="h3Img1 wow animate__animated animate__fadeInUp" />
                <img
                    :src="item.logo"
                    class="h3Imgs wow animate__animated animate__fadeInDown"
                    v-for="item in twelve"
                    :key="item.id"
                    @click="goDetail(item)"
                />
            </div>
        </div>
        <div class="hbBox4">
            <img src="http://iv.okvu.cn/vugw/img/hc124Bg.jpg" alt="" class="h4Bg" />
            <div class="hb4Top wow animate__animated animate__fadeInUp">行业解决方案</div>
            <div class="hb4Box">
                <div
                    class="h4Left animate__animated"
                    v-for="(item, index) in listContent"
                    :key="index"
                    v-show="showH4L === index"
                    :class="{ animate__fadeInUp: theAni === index }"
                >
                    <p class="hl1">{{ item.title }}</p>
                    <p class="hl2">{{ item.p }}</p>
                    <div class="hl3">
                        <ul>
                            <li class="hl3Li" v-for="(el, ind) in item.children" :key="ind">
                                <img src="http://iv.okvu.cn/vugw/img/26.png" alt="" class="h3Icon" />
                                {{ el }}
                            </li>
                        </ul>
                    </div>
                    <div class="hl4">
                        详情咨询
                        <div class="adbImg">
                            <img class="aiImg" :src="getWebData().public_phone" alt="" />
                        </div>
                    </div>
                    <div class="hl5">
                        <el-carousel height="100px" arrow="never" :autoplay="true" :interval="2000" indicator-position="none">
                            <el-carousel-item>
                                <ul class="h5Ul">
                                    <li class="hl5Li" v-for="item in ten.slice(0, 5)" :key="item.id" @click="goDetail(item)">
                                        <img :src="item.logo" />
                                    </li>
                                </ul>
                            </el-carousel-item>
                            <el-carousel-item v-if="ten.slice(5, 10).length">
                                <ul class="h5Ul">
                                    <li class="hl5Li" v-for="item in ten.slice(5, 10)" :key="item.id" @click="goDetail(item)">
                                        <img :src="item.logo" />
                                    </li>
                                </ul>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
                <div class="h4Right">
                    <ul>
                        <li
                            class="h4rLi"
                            v-for="(item, index) in list"
                            :key="index"
                            @click="getH4(index)"
                            :class="{ h4Color: h4C === index }"
                        >
                            <img src="http://iv.okvu.cn/vugw/img/33.png" alt="" class="h4Act" v-show="h4A === index" />
                            <img src="http://iv.okvu.cn/vugw/img/32.png" alt="" class="h4lIcon" /> {{ item }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hbBox5">
            <div class="hb5Top wow animate__animated animate__fadeInUp">品牌保证</div>
            <div class="hb5List">
                <ul>
                    <li class="h5lLi" v-for="item in 6" :key="item">
                        <div class="h5lBox">
                            <div class="h5b1 wow animate__animated animate__fadeInUp">
                                <img src="http://iv.okvu.cn/vugw/img/hc1251.png" alt="" />
                            </div>
                            <div class="h5b2">
                                <p class="h21 wow animate__animated animate__fadeInUp">6年专注网站建设</p>
                                <p class="h22 wow animate__animated animate__fadeInUp">
                                    6年行业经验，我们更懂网站，从根本上了解客户需求
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="hb5More">
                详情咨询
                <div class="adbImg">
                    <img class="aiImg" :src="getWebData().public_phone" alt="" />
                </div>
            </div>
        </div>
        <div class="hbBox6">
            <div class="hb6Top wow animate__animated animate__fadeInUp">案例展示</div>
            <div class="hb6List">
                <ul>
                    <li
                        class="h6lLi wow animate__animated animate__fadeInUp"
                        v-for="item in six"
                        :key="item.id"
                        @click.prevent="goDetail(item)"
                    >
                        <img v-lazy="item.little_image" />
                        <div class="h6Mask" v-if="item.qrcode || item.logo">
                            <img v-if="item.qrcode" v-lazy="item.qrcode" class="h6mCode" />
                            <img v-else v-lazy="item.logo" class="h6mCode" />
                            <!-- <p class="h6m1">王老吉</p> -->
                        </div>
                    </li>
                </ul>
            </div>
            <div class="hb6More">
                详情咨询
                <div class="adbImg">
                    <img class="aiImg" :src="getWebData().public_phone" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "HsContent12",
    inject: ["getWebData", "getAdvData"],
    data() {
        return {
            h4A: 1,
            h4C: 1,
            showH4L: 1,
            theAni: 1,
            six: [],
            ten: [],
            twelve: [],
            list: ["集团上市公司1", "集团上市公司2", "集团上市公司3", "集团上市公司4", "集团上市公司5", "集团上市公司6"],
            listContent: [
                {
                    title: "集团上市公司1",
                    p: "集团建站提供多站群服务，一站式完成多站搭建统一管理",
                    children: ["jjj", "jjj", "jjj"],
                },
                {
                    title: "集团上市公司2",
                    p: "集团建站提供多站群服务，一站式完成多站搭建统一管理",
                    children: ["jjj", "jjj", "jjj"],
                },
                {
                    title: "集团上市公司3",
                    p: "集团建站提供多站群服务，一站式完成多站搭建统一管理",
                    children: ["jjj", "jjj", "jjj"],
                },
                {
                    title: "集团上市公司4",
                    p: "集团建站提供多站群服务，一站式完成多站搭建统一管理",
                    children: ["jjj", "jjj", "jjj"],
                },
                {
                    title: "集团上市公司5",
                    p: "集团建站提供多站群服务，一站式完成多站搭建统一管理",
                    children: ["jjj", "jjj", "jjj"],
                },
                {
                    title: "集团上市公司6",
                    p: "集团建站提供多站群服务，一站式完成多站搭建统一管理",
                    children: ["jjj", "jjj", "jjj"],
                },
            ],
        };
    },
    async created() {
        await this.getData();
        this.$nextTick(() => {
            this.$AOS.init({
                offset: 200,
                duration: 600,
                easing: "ease-in-sine",
                delay: 100,
            });
            new this.$wow.WOW().init({
                boxClass: "wow",
                animateClass: "animate__animated",
                offset: 0,
                mobile: true,
                live: true,
            });
        });
    },
    mounted() {},
    methods: {
        getH4(i) {
            this.h4A = i;
            this.h4C = i;
            this.showH4L = i;
            this.theAni = i;
        },
        async getData() {
            const {
                data: { six, ten, twelve },
            } = await axios.get("api/project/brand");
            this.six = six;
            this.ten = ten;
            this.twelve = twelve;
        },
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            // 点击后的回调
            this.$emit("callBack");
        },
    },
};
</script>

<style lang="less" scoped>
.hc12Box {
    width: 100%;
    margin-top: 78px;
    overflow: hidden;
    .hbBox1 {
        width: 100%;
        height: 619px;
        position: relative;
        .hb1Bg {
            // width: 100%;
            height: 100%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
        }
        .hb1P1 {
            max-width: 1280px;
            height: 71px;
            line-height: 71px;
            font-size: 54px;
            position: absolute;
            top: 120px;
            left: 450px;
            .h1p1 {
                max-width: 216px;
                height: 71px;
                color: white;
                float: left;
            }
            .h1p2 {
                width: 324px;
                height: 71px;
                color: #ffcc00;
                margin-left: 20px;
                float: left;
            }
        }

        .hb1p {
            max-width: 1280px;
            height: 100%;
            margin: 0 auto;
            position: relative;
            .hb1Ps {
                width: 900px;
                height: 46px;
                line-height: 46px;
                position: absolute;
                bottom: 90px;
                left: 280px;
                .hp1 {
                    min-width: 292px;
                    height: 46px;
                    font-size: 20px;
                    color: white;
                    float: left;
                }
                .hp2 {
                    min-width: 99px;
                    height: 46px;
                    font-size: 37px;
                    color: #ffcd05;
                    float: left;
                    margin-left: 20px;
                }
            }
        }
    }
    .hbBox2 {
        width: 100%;
        height: 900px;
        background: url("http://iv.okvu.cn/vugw/img/3.jpg") no-repeat center/cover;
        overflow: hidden;
        .dots {
            display: flex;
            margin: 0 auto;
            justify-content: center;
            .dot {
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: #0056ff;
                margin: 0 5px;
                animation: an 1s infinite;
                @keyframes an {
                    0% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(2);
                    }
                    100% {
                        transform: scale(1);
                    }
                }
                &:nth-of-type(1) {
                    animation-delay: -0.06 * 1s;
                }
                &:nth-of-type(2) {
                    animation-delay: -0.06 * 2s;
                }
                &:nth-of-type(3) {
                    animation-delay: -0.06 * 3s;
                }
                &:nth-of-type(4) {
                    animation-delay: -0.06 * 4s;
                }
                &:nth-of-type(5) {
                    animation-delay: -0.06 * 5s;
                }
                &:nth-of-type(6) {
                    animation-delay: -0.06 * 6s;
                }
                &:nth-of-type(7) {
                    animation-delay: -0.06 * 7s;
                }
                &:nth-of-type(8) {
                    animation-delay: -0.06 * 8s;
                }
                &:nth-of-type(9) {
                    animation-delay: -0.06 * 9s;
                }
                &:nth-of-type(10) {
                    animation-delay: -0.06 * 10s;
                }
                &:nth-of-type(11) {
                    animation-delay: -0.06 * 11s;
                }
                &:nth-of-type(12) {
                    animation-delay: -0.06 * 12s;
                }
                &:nth-of-type(13) {
                    animation-delay: -0.06 * 13s;
                }
                &:nth-of-type(14) {
                    animation-delay: -0.06 * 14s;
                }
                &:nth-of-type(15) {
                    animation-delay: -0.06 * 15s;
                }
                &:nth-of-type(16) {
                    animation-delay: -0.06 * 16s;
                }
            }
        }
        .hb2Top {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 37px;
            text-align: center;
            margin-top: 50px;
        }
        .hb2List {
            max-width: 1000px;
            height: 670px;
            margin: 0 auto;
            ul {
                width: 100%;
                height: 100%;
                .h2l {
                    width: 280px;
                    height: 277px;
                    border-radius: 10px;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    background-color: white;
                    transition: 0.5s;
                    margin-left: 40px;
                    margin-top: 20px;
                    float: left;
                    position: relative;
                    .h2Tb {
                        width: 100%;
                        height: 30px;
                        background-color: white;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        position: absolute;
                        transition: 0.3s;
                    }
                    .h2Bb {
                        width: 100%;
                        height: 30px;
                        background-color: white;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        position: absolute;
                        bottom: 0;
                        transition: 0.3s;
                    }
                    .h2lBox {
                        width: 90%;
                        height: 90%;
                        margin: 0 auto;
                        margin-top: 5%;
                        overflow: hidden;
                        transition: 0.3s;

                        .hb1 {
                            width: 82px;
                            height: 82px;
                            margin: 0 auto;
                            margin-top: 10px;
                            position: relative;
                            overflow: hidden;
                            img {
                                width: 100%;
                            }
                        }
                        .hb2 {
                            width: 100%;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            font-size: 22px;
                            margin-top: 5px;
                        }
                        .hb3 {
                            width: 100%;
                            height: 105px;
                            font-size: 12px;
                            line-height: 21px;
                            text-align: center;
                            margin-top: 5px;
                        }
                    }
                }

                .h2l:nth-child(n + 4) {
                    margin-top: 50px;
                }
                .h2l:hover {
                    .h2Tb {
                        transform: translateY(-20px);
                    }
                    .h2Bb {
                        transform: translateY(20px);
                    }
                }
            }
        }
        .hb2More {
            width: 127px;
            height: 40px;
            margin: 0 auto;
            margin-top: 10px;
            font-size: 15px;
            line-height: 40px;
            text-align: center;
            border-radius: 25px;
            color: white;
            background-color: #0f5aff;
            transition: 0.5s;
            .adbImg {
                width: 100px;
                height: 100px;
                position: absolute;
                bottom: 60px;
                right: 15%;
                opacity: 0;
                translate: 1s;
                .aiImg {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                }
            }
        }
        .hb2More:hover {
            .adbImg {
                opacity: 1;
                translate: 1s;
            }
            transform: translateY(-10px);
        }
    }
    .hbBox3 {
        width: 100%;
        height: 741px;
        background: url("http://iv.okvu.cn/vugw/img/7.jpg");
        overflow: hidden;
        .hb3Top {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 37px;
            text-align: center;
            margin-top: 30px;
        }
        .hb3Box {
            max-width: 900px;
            height: 550px;
            margin: 0 auto;
            margin-top: 40px;
            position: relative;
            .h3Img1 {
                width: 360px;
                height: 140px;
                position: absolute;
                top: 150px;
                left: 250px;
            }
            .h3Imgs {
                object-fit: contain;
            }
            .h3Imgs:nth-child(2) {
                width: 150px;
                height: 150px;
                position: absolute;
                left: -52px;
                top: 100px;
            }
            .h3Imgs:nth-child(3) {
                width: 130px;
                height: 130px;
                position: absolute;
                left: 180px;
                top: 20px;
            }
            .h3Imgs:nth-child(4) {
                width: 102px;
                height: 102px;
                position: absolute;
                left: 500px;
                top: 20px;
            }
            .h3Imgs:nth-child(5) {
                width: 123px;
                height: 123px;
                position: absolute;
                left: 600px;
                top: 120px;
            }
            .h3Imgs:nth-child(6) {
                width: 140px;
                height: 140px;
                position: absolute;
                right: -50px;
                top: 70px;
            }
            .h3Imgs:nth-child(7) {
                width: 114px;
                height: 114px;
                position: absolute;
                right: -50px;
                top: 300px;
            }
            .h3Imgs:nth-child(8) {
                width: 114px;
                height: 114px;
                position: absolute;
                right: 250px;
                top: 280px;
            }
            .h3Imgs:nth-child(9) {
                width: 114px;
                height: 114px;
                position: absolute;
                left: 200px;
                top: 320px;
            }
            .h3Imgs:nth-child(10) {
                width: 110px;
                height: 110px;
                position: absolute;
                left: 50px;
                top: 250px;
            }
            .h3Imgs:nth-child(11) {
                width: 139px;
                height: 139px;
                position: absolute;
                left: -20px;
                top: 400px;
            }
            .h3Imgs:nth-child(12) {
                width: 146px;
                height: 146px;
                position: absolute;
                left: 350px;
                top: 400px;
            }
            .h3Imgs:nth-child(13) {
                width: 117px;
                height: 117px;
                position: absolute;
                right: 100px;
                top: 400px;
            }
            .h3Imgs {
                transition: 0.3s;
            }
            .h3Imgs:hover {
                transform: scale(1.1);
            }
        }
    }
    .hbBox4 {
        width: 100%;
        height: 700px;
        overflow: hidden;
        position: relative;
        text-align: left;
        .h4Bg {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
        }
        .hb4Top {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 37px;
            color: white;
            margin-top: 50px;
            position: relative;
            z-index: 9;
        }
        .hb4Box {
            width: 70%;
            height: 500px;
            margin: 0 auto;
            margin-top: 70px;
            .h4Left {
                width: 600px;
                min-height: 420px;
                float: left;
                color: white;
                .hl1 {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 30px;
                }
                .hl2 {
                    width: 100%;
                    height: 18px;
                    font-size: 18px;
                    line-height: 18px;
                    margin-top: 10px;
                }
                .hl3 {
                    width: 100%;
                    min-height: 162px;
                    margin-top: 20px;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-around;
                        .hl3Li {
                            width: 100%;
                            min-height: 21px;
                            line-height: 21px;
                            font-size: 15px;
                            .h3Icon {
                                height: 21px;
                                float: left;
                                margin-right: 10px;
                            }
                        }
                        .hl3Li:nth-child(n + 2) {
                            margin-top: 20px;
                        }
                    }
                }
                .hl4 {
                    width: 127px;
                    height: 37px;
                    background-color: #7781f1;
                    cursor: pointer;
                    line-height: 37px;
                    font-size: 12px;
                    text-align: center;
                    border-radius: 25px;
                    margin-top: 20px;
                    position: relative;
                    transform: translateY(0px);
                    transition: 0.5s linear;
                    .adbImg {
                        width: 100px;
                        height: 100px;
                        position: absolute;
                        bottom: 60px;
                        right: 15%;
                        opacity: 0;
                        transition: 0.5s;
                        .aiImg {
                            width: 100%;
                            height: 100%;
                            border-radius: 20px;
                        }
                    }
                }
                .hl4:hover {
                    transform: translateY(-10px);
                    .adbImg {
                        opacity: 1;
                    }
                }
                .hl5 {
                    width: 100%;
                    height: 60px;
                    margin-top: 30px;
                    .h5Ul {
                        width: 100%;
                        height: 60px;
                        display: flex;
                        // justify-content: space-around;
                        .hl5Li {
                            width: 100px;
                            height: 60px;
                            border-radius: 10px;
                            overflow: hidden;
                            cursor: pointer;
                            margin-right: 25px;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }
            }
            .h4Right {
                width: 125px;
                height: 455px;
                float: right;
                color: white;
                border-left: 1px solid rgba(255, 255, 255, 0.1);
                position: relative;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    // justify-content: space-around;
                    // flex-wrap: wrap;
                    flex-direction: column;
                    align-items: center;
                    .h4rLi {
                        width: 100%;
                        height: 18px;
                        font-size: 13px;
                        line-height: 18px;
                        text-align: right;
                        position: relative;
                        color: #ffffff99;
                        cursor: pointer;
                        margin-bottom: 20px;
                        .h4Act {
                            width: 42px;
                            height: 42px;
                            position: absolute;
                            left: -22px;
                            top: -12px;
                        }
                        .h4lIcon {
                            position: absolute;
                            left: -10px;
                            cursor: pointer;
                        }
                    }
                    .h4rLi:hover {
                        color: white;
                    }
                    .h4Color {
                        color: white;
                        font-size: 15px;
                    }
                }
            }
        }
    }
    .hbBox5 {
        max-width: 1430px;
        height: 850px;
        overflow: hidden;
        margin: 0 auto;
        .hb5Top {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 37px;
            margin-top: 50px;
        }
        .hb5List {
            width: 100%;
            height: 541px;
            margin-top: 70px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                .h5lLi {
                    width: 50%;
                    height: 181px;
                    border: 1px solid #e1e6f0;
                    box-sizing: border-box;
                    .h5lBox {
                        width: 450px;
                        height: 129px;
                        .h5b1 {
                            width: 82px;
                            height: 82px;
                            margin-top: 20px;
                            float: left;
                            position: relative;
                            overflow: hidden;
                            img {
                                width: 100%;
                            }
                        }
                        .h5b2 {
                            width: 322px;
                            height: 192px;
                            float: left;
                            margin-top: 30px;
                            .h21 {
                                width: 100%;
                                height: 30px;
                                line-height: 30px;
                                font-size: 22px;
                            }
                            .h22 {
                                width: 100%;
                                height: 21px;
                                line-height: 21px;
                                font-size: 12px;
                                margin-top: 10px;
                            }
                        }
                    }
                }
                .h5lLi:nth-child(odd) {
                    .h5lBox {
                        margin-top: 20px;
                        margin-left: 200px;
                    }
                }
                .h5lLi:nth-child(even) {
                    .h5lBox {
                        margin-top: 20px;
                        margin-left: 60px;
                    }
                }
                .h5lLi:hover {
                    background: url("http://iv.okvu.cn/vugw/img/35.png") no-repeat center;
                }
            }
        }
        .hb5More {
            width: 127px;
            height: 40px;
            margin: 0 auto;
            margin-top: 40px;
            font-size: 15px;
            line-height: 40px;
            text-align: center;
            border-radius: 25px;
            color: white;
            background-color: #0f5aff;
            transition: 0.5s;
            .adbImg {
                width: 100px;
                height: 100px;
                position: absolute;
                bottom: 60px;
                right: 15%;
                opacity: 0;
                translate: 1s;
                .aiImg {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                }
            }
        }
        .hb5More:hover {
            transform: translateY(-10px);
            .adbImg {
                opacity: 1;
                translate: 1s;
            }
        }
    }
    .hbBox6 {
        width: 100%;
        height: 700px;
        background: url("http://iv.okvu.cn/vugw/img/36.jpg") no-repeat center;
        background-size: 100%;
        overflow: hidden;
        .hb6Top {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 37px;
            margin-top: 30px;
        }
        .hb6List {
            max-width: 996px;
            height: 470px;
            margin: 0 auto;
            margin-top: 70px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                .h6lLi {
                    width: 274px;
                    height: 216px;
                    border-radius: 10px;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    & > img {
                        width: 100%;
                        height: 100%;
                        transition: 0.5s;
                        object-fit: cover;
                    }
                    .h6Mask {
                        width: 274px;
                        height: 216px;
                        border-radius: 10px;
                        background-color: rgba(0, 0, 0, 0.5);
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: 0.5s;
                        opacity: 0;
                        .h6mCode {
                            width: 100px;
                            height: 100px;
                            border-radius: 5px;

                            // display: block;
                            // margin: 0 auto;
                            // margin-top: 40px;
                            object-fit: contain;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        .h6m1 {
                            width: 100%;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            color: white;
                            font-size: 25px;
                            margin-top: 20px;
                        }
                    }
                }
                .h6lLi:hover {
                    img {
                        transform: scale(1.1);
                    }
                    .h6Mask {
                        opacity: 1;
                    }
                }
            }
        }
        .hb6More {
            width: 127px;
            height: 40px;
            margin: 0 auto;
            margin-top: 25px;
            font-size: 15px;
            line-height: 40px;
            text-align: center;
            border-radius: 25px;
            color: white;
            background-color: #0f5aff;
            transition: 0.5s;
            cursor: pointer;
            .adbImg {
                width: 100px;
                height: 100px;
                position: absolute;
                bottom: 60px;
                right: 15%;
                opacity: 0;
                translate: 1s;
                .aiImg {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                }
            }
        }
        .hb6More:hover {
            transform: translateY(-10px);
            .adbImg {
                opacity: 1;
                translate: 1s;
            }
        }
    }
}
</style>
